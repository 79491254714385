import Register from './pages/Register';
import Login from './pages/Login';
import Home from './pages/Home';
import Layout from './components/Layout';
import Editor from './components/Editor';
import Admin from './components/Admin';
import Missing from './components/Missing';
import Unauthorized from './components/Unauthorized';
import Lounge from './components/Lounge';
import LinkPage from './components/LinkPage';
import RequireAuth from './components/RequireAuth';
import PersistLogin from './components/PersistLogin';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import { ToastContainer, toast } from 'react-toastify';
import ReactToastify from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Profile } from './pages/Profile';
import { States } from './pages/States';
import { Carriers } from './pages/Carriers';
import { StatesUpdated } from './pages/StatesUpdated';
import Navbar from './components/Navbar'
import { Footer } from './components/Footer';
import { ComplianceReport } from './pages/ComplianceReport';
import { ComplianceGenerator } from './pages/ComplianceGenerator';
import { QrCode } from './pages/QrCode';
import { ForgotPassword } from './pages/ForgotPassword';
import { ResetPassword } from './pages/ResetPassword';
import { Account } from './pages/Account';
import { Logout } from './pages/Logout';







const ROLES = {
  'User': 2001,
  'Editor': 1984,
  'Admin': 5150
}

function App() {
  //const notify = () => toast("Wow so easy!");
  return (
    <div>
    <Navbar />
    {/*<button onClick={notify}>Notify!</button>*/}
    <ToastContainer position="top-center"/>  

    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="linkpage" element={<LinkPage />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="forgotpassword" element={<ForgotPassword />} />
        <Route path="resetpassword" element={<ResetPassword />} />
        
        {/* we want to protect these routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
            <Route path="/" element={<Home />} />
            <Route path="/dashboard" element={<Dashboard />} />   
            <Route path="/profile" element={<Profile />} />      
            <Route path="/states" element={<States />} />    
            <Route path="/compliancereport" element={<ComplianceReport />} /> 
            <Route path="/compliancegenerator" element={<ComplianceGenerator/>} />
            <Route path="/statesupdated" element={<StatesUpdated />} />
            <Route path="/qrcode" element={<QrCode />} />
            <Route path="/carriers" element={<Carriers />} />
            <Route path="/account" element={<Account />} />
            <Route path="/logout" element={<Logout />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.Editor]} />}>
            <Route path="editor" element={<Editor />} />
          </Route>


          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="admin" element={<Admin />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.Editor, ROLES.Admin]} />}>
            <Route path="lounge" element={<Lounge />} />
          </Route>
        </Route>

        {/* catch all */}
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
    <Footer/>
    </div>
  );
}

export default App;