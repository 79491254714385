import { useNavigate } from "react-router-dom"
import { Stack, TextField, Button } from '@mui/material'
import { Header } from "./Header";



const Unauthorized = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);
    const login = () => navigate('/login')

    return (
        <section className="container">
        <div> <Header title={`Log In for Clarity`}  />    </div>

            <br />
            <p>You may not have access to the requested page. 
            You may also have refreshed the pge forcing a log out.</p>
            <div className="flexGrow">
                <Button onClick={goBack}>Go Back</Button> &nbsp;
                <Button onClick={login}>Log in</Button>
            </div>
        </section>
    )
}

export default Unauthorized
