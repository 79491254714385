import { useRef, useState, useEffect } from 'react';
import { Stack, TextField, Button } from '@mui/material'
import useAuth from '../hooks/useAuth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import useInput from '../hooks/useInput';
import useToggle from '../hooks/useToggle';

import axios from '../api/axios';
import { Header } from '../components/Header';
import { useUser } from '../context/UserContext';

const LOGIN_URL = '/auth';

const Login = () => {
    let { username, email, ZipCode, CountyFIPS, StateCode, setUsername, setEmail, setZipCode, setCountyFIPS, setStateCode } = useUser();
    const handleUserNameChange = (newUserName) => { setUsername(newUserName);};
    const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef();
    const errRef = useRef();

    const [user, resetUser, userAttribs] = useInput('user', '')
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [check, toggleCheck] = useToggle('persist', false);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ user, pwd }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            const accessToken = response?.data?.accessToken;
            const roles = response?.data?.roles;
            setAuth({ user, pwd, roles, accessToken });
            setUsername(user);
            //handleUserNameChange(user);
            setEmail(email)
            resetUser();
            setPwd('');
            navigate(from, { replace: true });
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }

    return (

        <section className="container">
        <Header  title={'Log In'}/>
        <br />     
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            <br />
            <form onSubmit={handleSubmit}>
            <h3>Username:</h3>
            <Stack spacing={2}>
            <TextField  variant='standard' id="standard-basic"
                type="text"  
                ref={userRef} autoComplete="new-username" {...userAttribs}
                required
            />
            </Stack>
            <br />
            <h3>Password:</h3>
            <Stack spacing={2}>
            <TextField  variant='standard' 
            value={pwd} type="password" id="password" required autoComplete="new-password"
            onChange={(e) => setPwd(e.target.value)} />
            </Stack>
            <Stack spacing={2}>
                <br />
                <Button type='submit' variant='contained'>Log In</Button> 
                <br />
            </Stack>

                <div className="persistCheck">
                    <input
                        type="checkbox"
                        id="persist"
                        onChange={toggleCheck}
                        checked={check}
                    />
                    <label htmlFor="persist">Trust This Device</label>
                </div>
            </form>
            <p>

            <span className="line">
                <Link to="/forgotpassword">Forgot Your Password</Link>
            </span>
        </p>
            <p>
                Need an Account?  &nbsp;
                <span className="line">
                    <Link to="/register">Sign Up</Link>
                </span>
            </p>
        </section>

    )
}

export default Login
